<template>
	<div class="project-status-dashboard border h-100">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Assets By Suppliers</div>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<v-row>
				<v-col md="12">
					<div>
						<apexchart
							type="donut"
							:options="chartOptions"
							:series="series"
							width="280px"
							class="d-flex justify-content-center"
						></apexchart>
					</div>
				</v-col>
				<v-col md="12">
					<div class="total-project">
						<div class="total_no">{{ total_supplier }}</div>
						<div class="fw-600">
							<span class="text-muted">Total Suppliers</span>
						</div>
					</div>
					<div class="project-desc">
						<ul class="pa-0 ul">
							<li class="d-flex li" v-for="(row, index) in supplierdata" :key="index">
								<div class="d-flex">
									<div :class="`status_color mr-3 ${row.color}`"></div>
									<div class="name text-truncate">{{ row.display_name }}</div>
								</div>
								<div class="project-number text-truncate">{{ row.total_asset }} <span>Assets</span></div>
								<div :class="`project-time text-truncate ${row.color}--text`"></div>
							</li>
						</ul>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
	name: "ProjectStatus",
	data() {
		return {
			series: [44, 55, 77, 15],
			chartOptions: {
				chart: {
					width: 280,
					type: "donut",
				},
				legend: {
					position: "bottom",
					show: false,
				},
			},

			supplierdata: [],
			total_supplier: 0,
		};
	},
	methods: {
		getGroups() {
			ApiService.setHeader();
			ApiService.get("dashboard-supplier").then(({ data }) => {
				this.supplierdata = data.asset_by_supplier;
				this.total_supplier = data.total_supplier;
			});
		},
	},
	mounted() {
		this.getGroups();
	},
};
</script>
